@import '../../utils/variables.scss';
@import '../../utils/mixins.scss';

.headerMenuItemPopper {
  position: absolute;
  top: 0;
  left: 0;

  background-color:#fff;

  &__item {
    padding-top: $menuItem__padding_top;
    padding-left: $menuItem__padding_left;
    padding-right: $menuItem__padding_left;
    margin-bottom: 24px;

    cursor: auto;
  }

  &__caretDown {
    padding-left: 12px;
  }

  &__link {
    @include headerNavFont();
    color: #cacaca;

    &:hover {
      color: #ff0000;
    }

    &_first {
      color: #000;

      &:hover {
        color: #000;
      }
    }
  }
}
