$bgColor: #C4C4C4;
$brRadius: 4px;
$itemPadding: 16px 23px 16px 16px;

.select {
  position: relative;
  width: 100%;

  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: italic;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.125rem;

  color: #111;

  cursor: pointer;

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: $bgColor;
    border-radius: $brRadius;
    padding: $itemPadding;

    &_noRadius {
      border-radius: $brRadius $brRadius 0 0;
    }
  }

  &__content {
    position: absolute;
    top: 99.8%;
    z-index: 99;

    width: 100%;
    border-radius: 0 0 $brRadius $brRadius;

    background-color: $bgColor;
  }

  &__item {
    padding: $itemPadding;

    color: #686868;

    transition: color 0.3s;

    &:hover {
      color: #FF0000;
    }
  }
}
