.prev, .next {
  position: absolute;
  top: 38%;
  z-index: 1000;

  display: block;
  width: 36px;
  height: 60px;

  background: url(../../assets/ourCases/sliderArrow.svg) 0 0 / 100% no-repeat;
  cursor: pointer;

  @media (max-width: 576px) {
    width: 20px;
    height: 34px;
  }
}

.prev {
  left: 0%;
  transform: rotate(180deg);

  @media (max-width: 576px) {
    transform: rotate(180deg);
  }
}

.next {
  left: 100%;
  transform: translate(-100%);
}

.swiper {
  padding-top: 40px;
}

.swiper-slide {
  width: 388px;
}

.swiper-pagination-bullet {
  width: 76px;
  height: 3px;
  background-color: #FFC700;
  cursor: pointer;
  border-radius: 0;
  padding-right: 20px;
  opacity: 1;

  &-active {
    background-color: #FF0000;
  }
}



.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 12px);
}

.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 99%;
}
