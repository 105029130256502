@import "../../utils/mixins.scss";
@import "../../utils/variables.scss";

.headerMenu {
  display: flex;

  &__item {
    position: relative;

    padding-top: $menuItem__padding_top;
    padding-left: $menuItem__padding_left;
    margin-right: 70px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__caretDown {
    padding-left: 12px;
  }

  &__link {
    @include headerNavFont();
    color: #fff;
  }
}
