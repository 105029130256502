.ourPartnersSlider.slick-slider {
  overflow: hidden;
}

.ourPartnersSlider .slick-list {
  padding-top: 35px;
}

.ourPartnersSlider .slick-track {
  display: flex;
  justify-content: space-between;
  gap: 48px;
  cursor: pointer;
}

.ourPartnersSlider .slick-dots {
  position: relative;
  top: -167px;
  display: flex!important;
  justify-content: center;
  gap: 20px;
}

.ourPartnersSlider .slick-dots li {
  width: 80px;
  height: 3px;
  background-color: #FFC700;
  cursor: pointer;
}

/* active point */
.ourPartnersSlider .slick-dots li.slick-active{
  background-color: #FF0000;

}
/* point element */
.ourPartnersSlider .slick-dots li button{
  display: block;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
