@mixin headerNavFont {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 900;
  font-style: italic;
  font-size: 0.875rem; // 14px (base: 16px)
  line-height: 1rem;
  letter-spacing: 0.05em;
  text-decoration: none;
  text-transform: uppercase;
}
