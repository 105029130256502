@font-face {
  font-family: 'Hoxton800italic';
  src: url(./fonts/Hoxton/Hoxton\ Sans\ ExtraBold\ Italic\ 800.ttf);
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
